import {AxiosInstance} from 'axios';

/*
 * todo: header must be explicitly allowed if url is outside the domain, investigate this:
 * @link https://git.designeo.cz/designeo/npm-libs/apibundle-js/-/blob/next/src/Actions/Action.ts#L87
 */
const removeActionNameHeader = (axios: AxiosInstance) => {
  return axios.interceptors.request.use((request) => {
    delete request.headers?.['ActionName'];

    return request;
  });
};

export default removeActionNameHeader;
