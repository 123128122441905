import ConfigurationService,
{configureConfigurationService} from '@/modules/Backoffice/services/ConfigurationService';

// TODO: improve this later (you don't have router here)
const parseUrl = () => {
  const routeRegex = /^\/(?<projectCode>[^/]+)\/(?<guid>[^/]+)(\/(?<rest>.*))*$/;

  const match = window.location.pathname.match(routeRegex);
  if (match && match.groups) {
    const {
      projectCode,
      guid,
    } = match.groups;

    return {
      projectCode,
      guid,
    };
  }

  throw new Error('Not a valid route.');
};

const redirectToNotFound = async () => {
  history.pushState({}, '', '/404');
};

export async function registerBackofficeModule(ctx) {
  try {
    const {projectCode} = parseUrl();
    console.log(projectCode, 'projectCode');

    const config = await ConfigurationService.fetchConfiguration(projectCode);
    configureConfigurationService([config]);
  } catch (e) {
    await redirectToNotFound();
    return;
  }


  return {};
}
