import {
  HeadClient,
  createHead,
} from '@vueuse/head';
import {ServiceAlreadyExistsError} from '@/moduleSystem/errors/ServiceAlreadyExistsError';
import {
  addService,
  getService,
} from '@/moduleSystem/inject';

export const serviceIdentifier = 'HeadClient';

export default (ctx) => {
  const headClient = createHead();

  ctx.app.use(headClient);

  try {
    addService(headClient, serviceIdentifier, {
      shared: false,
    });
  } catch (e) {
    if (!(e instanceof ServiceAlreadyExistsError)) {
      throw e;
    }
  }

  return {
    headClient,
  };
};

export const useHeadClient = () => getService<HeadClient>(serviceIdentifier);
