export default {
  en: {
    notification: {
      error: {
        general: {
          title: 'Error',
          description: 'An error has occured.',
        },
      },
    },
  },
  fr: {
    notification: {
      error: {
        general: {
          title: 'Erreur',
          description: 'une erreur est survenue.',
        },
      },
    },
  },
};
