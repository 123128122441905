import {RegisterRoutesOptions} from '@/moduleSystem';

export enum ROUTES__CORE {
  NOT_FOUND = 'routes__core__not-found',
}

export default [
  {
    path: '/:pathMatch(.*)*',
    name: ROUTES__CORE.NOT_FOUND,
    component: () => import('./pages/NotFoundPage.vue'),
  },
] as RegisterRoutesOptions;
