<template>
  <div
    class="notification-item"
    :class="{
      'notification-item--info': notificationType === NotificationTypeEnum.INFO,
      'notification-item--success': notificationType === NotificationTypeEnum.SUCCESS,
      'notification-item--warnings': notificationType === NotificationTypeEnum.WARNING,
      'notification-item--error': notificationType === NotificationTypeEnum.ERROR,
    }"
  >
    <div class="notification-item__wrapper">
      <div class="notification-item__icon-wrapper">
        <InformationCircleIcon
          v-if="NotificationTypeEnum.INFO === notificationType"
          class="notification-item__icon notification-item__icon--info"
          aria-hidden="true"
        />
        <CheckCircleIcon
          v-if="NotificationTypeEnum.SUCCESS === notificationType"
          name="check-circle"
          class="notification-item__icon notification-item__icon--success"
          aria-hidden="true"
        />
        <ExclamationIcon
          v-if="NotificationTypeEnum.WARNING === notificationType"
          class="notification-item__icon notification-item__icon--warning"
          aria-hidden="true"
        />
        <ExclamationCircleIcon
          v-if="NotificationTypeEnum.ERROR === notificationType"
          class="notification-item__icon notification-item__icon--error"
          aria-hidden="true"
        />
      </div>
      <div class="notification-item__text-wrapper">
        <p class="notification-item__title">
          {{ title }}
        </p>
        <p
          v-if="description"
          class="notification-item__description"
        >
          {{ description }}
        </p>
      </div>
      <div class="notification-item__close-wrapper">
        <button
          type="button"
          class="notification-item__close-button"
          @click="$emit('close')"
        >
          <span class="sr-only">Close</span>
          <XIcon
            class="notification-item__close-icon"
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CheckCircleIcon from '@heroicons/vue/24/solid/CheckCircleIcon';
import ExclamationCircleIcon from '@heroicons/vue/24/solid/ExclamationCircleIcon';
import ExclamationIcon from '@heroicons/vue/24/solid/ExclamationTriangleIcon';
import InformationCircleIcon from '@heroicons/vue/24/solid/InformationCircleIcon';
import XIcon from '@heroicons/vue/24/solid/XMarkIcon';
import {
  PropType,
  defineComponent,
} from 'vue';
import {NotificationTypeEnum} from '@/modules/Notification/services/NotificationManager';


export default defineComponent({
  components: {
    XIcon,
    InformationCircleIcon,
    CheckCircleIcon,
    ExclamationIcon,
    ExclamationCircleIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    notificationType: {
      type: String as PropType<NotificationTypeEnum>,
      default: NotificationTypeEnum.INFO,
    },
  },
  emits: ['close'],
  setup() {
    return {
      NotificationTypeEnum,
    };
  },
});
</script>

<style>
.notification-item {
  @apply w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-neutral-100 overflow-hidden;
}

.notification-item--info {
  @apply bg-white;
}

.notification-item--success {
  @apply bg-green-50;
}

.notification-item--warnings {
  @apply bg-yellow-50;
}

.notification-item--error {
  @apply bg-red-50;
}

.notification-item__wrapper {
  @apply p-4 flex items-start;
}

.notification-item__icon-wrapper {
  @apply flex-shrink-0;
}

.notification-item__icon {
  @apply h-6 w-6 text-green-400;
}

.notification-item__icon--info {
  @apply text-neutral-600;
}

.notification-item__icon--success {
  @apply text-green-600;
}

.notification-item__icon--warning {
  @apply text-yellow-600;
}

.notification-item__icon--error {
  @apply text-red-600;
}

.notification-item__text-wrapper {
  @apply ml-3 w-0 flex-1 pt-0.5;
}

.notification-item__title {
  @apply text-sm font-medium text-sharer-gray-900;
}

.notification-item__description {
  @apply mt-1 text-sm text-sharer-gray-500;
}

.notification-item__close-wrapper {
  @apply ml-4 flex-shrink-0 flex;
}

.notification-item__close-button {
  @apply rounded-md inline-flex text-sharer-gray-400 hover:text-sharer-gray-500 focus:outline-none focus:ring-2 focus:ring-neutral-400;
}

.notification-item__close-icon {
  @apply h-5 w-5;
}
</style>
