import {createContext} from '@designeo/js-helpers';
import {registerBackofficeModule} from '@/modules/Backoffice';
import {registerCoreModule} from '@/modules/Core';
import {registerModule as registerSharerModule} from '@/modules/Sharer';
import debugTools from '@/plugins/debugTools';
import imageProxy from '@/plugins/imageProxy';
import {registerSentry} from '@/plugins/Sentry/sentry';
import App from './App.vue';
import {registerNotificationModule} from './modules/Notification';
import {registerModuleSystem} from './moduleSystem';
import registerHeadClient from './plugins/HeadClient/headClient';
import {registerVueI18n} from './plugins/I18n/i18n';
import {registerRouter} from './plugins/Router/router';
import registerYup from './plugins/Yup/yup';

interface createContextOptions {
  createApp: import('vue').CreateAppFunction<any>,
}

const initApp = async () => {};

export const createCtx = async ({createApp}: createContextOptions) => {
  const app = createApp(App)
    .use(debugTools)
    .use(imageProxy, {
      baseUrl: window.origin,
    });

  const ctx = createContext({
    app,
  })
    .register(registerSentry)
    .register(registerModuleSystem)
    .register(registerCoreModule)
    .register(registerBackofficeModule)
    .register(registerNotificationModule)
    .register(registerSharerModule)
    .register(registerVueI18n)
    .register(registerRouter)
    .register(registerYup)
    .register(registerHeadClient)
  ;
  const resolvedContext = await ctx.resolve();
  app.config.globalProperties.$context = resolvedContext;

  await initApp();

  return resolvedContext;
};

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T
export type AppContext = ThenArg<ReturnType<typeof createCtx>>

export default createCtx;
