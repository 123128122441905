import './styles/index.css';
import {createApp} from 'vue';
import createContext from './createContext';
import {
  getRequestStorage,
  sharedStorage,
} from './moduleSystem/inject';

(async function() {
  window['global'] = window;
  window['__requestStorage'] = getRequestStorage();
  window['__sharedStorage'] = sharedStorage;
  const ctx = await createContext({
    createApp,
  });

  await ctx.router.isReady();

  ctx.app.mount('#app');
  window['ctx'] = ctx;
})();
