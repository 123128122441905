export default {
  en: {
    general: {
      confirm: 'Confirm',
      back: 'Return',
      send: 'Send',
      delete: 'Delete',
      select: 'Select',
      detail: 'Detail',
      edit: 'Edit',
      create: 'Create',
      loading: 'Loading ...',
      noResults: 'No result found.',
      print: 'Print',
      yes: 'Yes',
      no: 'No',
      close: 'Close',
      next: 'Suivant',
      previous: 'Previous',
      generate: 'Generate',
      success: 'Success',
      successfulSaved: 'Entry successfully saved.',
      successfulDelete: 'Entry successfully deleted.',
      all: 'All',
      currency: {
        czk: 'CZK',
      },
      open: 'Open',
    },
    grid: {
      actions: 'Actions',
      noResults: 'No results',
      resultsShown: 'Results shown:',
    },
    apiErrorHandler: {
      title: 'Error!',
      message: 'An errors occurred somewhere. Please try again.',
    },
    notFound: {
      title: 'Not found.',
      text: 'We are sorry, the page you requested could not be found.',
    },
    button: {
      submit: 'Save',
      cancel: 'Cancel',
    },
    messages: {
      confirmDelete: 'Are you sure you want to delete {0}?',
      clickToCopy: 'Click to copy to clipboard.',
      copiedClipboard: 'Copied to clipboard!',
    },
    validations: {
      regex: 'Field is not ',
      required: 'Field is required.',
      min: 'Field must be at least {min} characters long.',
      max: 'Field can be at most {max} characters long.',
      containsDigit: 'Field must include at least one digit.',
      passwordConfirmation: 'Passwords have to match.',
      email: 'Field must include a valid email address.',
      usernameNotUnique: 'This username is already taken.',
      emailNotUnique: 'This email is already taken.',
      number: 'Field must be numeric.',
      string: 'Field must be text.',
      array: {
        min: '{path} has to include at least {min} items.',
        max: '{path} can include at most {max} items.',
      },
    },
    header: {
      profile: {
        userProfile: 'User profile',
      },
    },
    tipTap: {
      menu: {
        bold: 'Bold',
        italic: 'Italic',
        underline: 'Underline',
        strike: 'Strikethrough',
        paragraph: 'Paragraph',
        heading: 'Heading',
        bulletList: 'Bullet List',
        orderedList: 'Ordered List',
        alignLeft: 'Align Left',
        alignRight: 'Align Right',
        alignCenter: 'Align Center',
        alignJustify: 'Align Justify',
        code: 'Code',
      },
    },
    serviceWorker: {
      confirm: {
        title: 'Update is ready',
        text: 'Switch to new version?',
        confirm: 'Yes',
        cancel: 'No',
      },
    },
    qrScanner: {
      title: 'Scan QR code',
    },
  },
  // todo
  fr: {
    general: {
      confirm: 'Confirmer',
      back: 'Retour',
      send: 'Envoyer',
      select: 'Selectionner',
      delete: 'Delete',
      detail: 'Detail',
      edit: 'Edit',
      create: 'Create',
      loading: 'Loading ...',
      noResults: 'No result found.',
      print: 'Print',
      yes: 'Yes',
      no: 'No',
      close: 'Fermer',
      next: 'Next',
      previous: 'Previous',
      generate: 'Generate',
      success: 'Succès',
      successfulSaved: 'Entry successfully saved.',
      successfulDelete: 'Entry successfully deleted.',
      all: 'All',
      currency: {
        czk: 'CZK',
      },
      open: 'Open',
    },
    grid: {
      actions: 'Actions',
      noResults: 'No results',
      resultsShown: 'Results shown:',
    },
    apiErrorHandler: {
      title: 'Error!',
      message: 'An errors occurred somewhere. Please try again.',
    },
    notFound: {
      title: 'Non trouvée.',
      text: 'Nous sommes désolés, la page que vous avez demandée n\'a pas pu être trouvée.',
    },
    button: {
      submit: 'Save',
      cancel: 'Cancel',
    },
    messages: {
      confirmDelete: 'Are you sure you want to delete {0}?',
      clickToCopy: 'Click to copy to clipboard.',
      copiedClipboard: 'Copied to clipboard!',
    },
    validations: {
      regex: 'Field is not ',
      required: 'Field is required.',
      min: 'Field must be at least {min} characters long.',
      max: 'Field can be at most {max} characters long.',
      containsDigit: 'Field must include at least one digit.',
      passwordConfirmation: 'Passwords have to match.',
      email: 'Field must include a valid email address.',
      usernameNotUnique: 'This username is already taken.',
      emailNotUnique: 'This email is already taken.',
      number: 'Field must be numeric.',
      string: 'Field must be text.',
      array: {
        min: '{path} has to include at least {min} items.',
        max: '{path} can include at most {max} items.',
      },
    },
    header: {
      profile: {
        userProfile: 'User profile',
      },
    },
    tipTap: {
      menu: {
        bold: 'Bold',
        italic: 'Italic',
        underline: 'Underline',
        strike: 'Strikethrough',
        paragraph: 'Paragraph',
        heading: 'Heading',
        bulletList: 'Bullet List',
        orderedList: 'Ordered List',
        alignLeft: 'Align Left',
        alignRight: 'Align Right',
        alignCenter: 'Align Center',
        alignJustify: 'Align Justify',
        code: 'Code',
      },
    },
    serviceWorker: {
      confirm: {
        title: 'Update is ready',
        text: 'Switch to new version?',
        confirm: 'Yes',
        cancel: 'No',
      },
    },
    qrScanner: {
      title: 'Scannez le code QR',
      message: 'Scannez ici les QR Codes présents sur le stand « Futur' +
        ' de la distribution omnicanale » pour découvrir les expériences\n',
    },
  },
};
