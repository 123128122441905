import {useAxios} from "@/plugins/Axios/axios";
import ObjectType from "@/model/types/ObjectType";
import {createAction} from "@designeo/apibundle-js";
export interface ProjectConfiguration<T extends object = object> {
  createdAt: string,
  modifiedAt: string,
  guid: string,
  shortCode: string,
  name: string,
  configuration: T,
  state: string,
  clientId: number
  clientName: string
  clientCode: string
  appId: number,
  appName: string,
  appIdentifier: string,
  eventGuid?: string,
  eventName?: string
}

export const apiLoadConfiguration = createAction({
  method: 'GET',
  path: `${APP_CONFIG.backofficeUrl}/api/v1/external/project/by-code/{shortCode}/config?_format=json`,
  errorTypes: {},
  outputTypes: {
    200: new ObjectType<ProjectConfiguration>(),
  },
  paramsType: new ObjectType<{shortCode: string}>(),
  axios: useAxios(),
})
