import {
  AxiosInstance,
  default as defaultAxios,
} from 'axios';
import {ServiceAlreadyExistsError} from '@/moduleSystem/errors/ServiceAlreadyExistsError';
import {
  addService,
  getService,
} from '@/moduleSystem/inject';
import removeActionNameHeader from '@/plugins/Axios/utils/removeActionNameHeader';

export const axiosIdentifier = 'axios';

const createAxios = (serviceIdentifier: string): AxiosInstance => {
  const axios = defaultAxios.create();

  // setXAcceptLanguage(axios);
  // setAuthorizationHeader(axios);
  // forceEnvelopeResponse(axios);
  removeActionNameHeader(axios);
  // manageUnauthorized(axios);

  try {
    addService<AxiosInstance>(axios, serviceIdentifier, {
      shared: false,
    });
  } catch (e) {
    if (!(e instanceof ServiceAlreadyExistsError)) {
      throw e;
    }
  }

  return axios;
};

createAxios(axiosIdentifier);

export const useAxios = (): AxiosInstance => getService(axiosIdentifier);
