import * as yup from 'yup';

export const getValidationTranslationKey = (rule) => {
  return `validations.${rule}`;
};

export default (ctx) => {
  const i18n = ctx.i18n.global;

  const t = (rule, args = {}) => {
    return i18n.t(getValidationTranslationKey(rule), args);
  };

  yup.setLocale({
    mixed: {
      required: (args) => t('required', args),
      notType: (args) => t(args.type),
    },
    string: {
      min: (args) => t('min', args),
      max: (args) => t('max', args),
      email: (args) => t('email', args),
    },
  });

  return {
    yup,
  };
};
