import {captureConsoleIntegration} from '@sentry/integrations';
import * as Sentry from '@sentry/vue';

export const registerSentry = async ({app}) => {
  // do not send errors in development mode
  if (!APP_CONFIG.isBuild) {
    return;
  }

  try {
    Sentry.init({
      app,
      dsn: APP_CONFIG.sentryDsn,
      tracesSampleRate: 1.0,
      environment: APP_CONFIG.environment,
      release: `${APP_CONFIG.sentryProject}@${APP_CONFIG.version}`,
      integrations: [
        captureConsoleIntegration({
          levels: ['log', 'info', 'warn', 'error', 'debug', 'assert'],
        }),
      ],
    });
  } catch (e) {
    console.error(e);
    console.error('Sentry is disabled.');
  }
};
