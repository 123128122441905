import {
  flatten,
  isFunction,
  merge,
} from 'lodash-es';
import {
  LocaleMessages,
  VueMessageType,
} from 'vue-i18n';
import {RouteRecordRaw} from 'vue-router';

export type RegisterRoutesOptions = RouteRecordRaw[]|(() => Promise<RouteRecordRaw[]>)
type registerRouteFn = (routes: RouteRecordRaw[]|(() => Promise<RouteRecordRaw[]>)) => Promise<void>
type registerTranslationsFn = (messages: LocaleMessages|(() => Promise<LocaleMessages[]>)) => Promise<void>


export interface ModuleSystem extends Object {
  registerRoutes: registerRouteFn,
  registerTranslations: registerTranslationsFn,
  getRegisteredRoutes(): RouteRecordRaw[],
  getTranslations(): LocaleMessages<VueMessageType>,
}


export async function registerModuleSystem(): Promise<ModuleSystem> {
  const routes: RouteRecordRaw[][] = [];
  const messages: LocaleMessages<VueMessageType> = {};

  return {
    async registerTranslations(newMessages) {
      merge(messages, newMessages);
    },
    async registerRoutes(newRoutes) {
      if (isFunction(newRoutes)) {
        routes.push(await newRoutes());
      } else {
        routes.push(newRoutes);
      }
    },
    getRegisteredRoutes() {
      return flatten(routes);
    },
    getTranslations() {
      return messages;
    },
  };
}
