<template>
  <TransitionRoot
    as="template"
    :show="needRefresh"
  >
    <DialogBase
      as="div"
      class="relative z-10"
      @close="needRefresh = false"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral-900 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative bg-black rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6"
            >
              <div>
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-800">
                  <CheckIcon
                    class="h-6 w-6 text-green-300"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-lg leading-6 font-medium text-neutral-100"
                  >
                    {{ t('serviceWorker.confirm.title') }}
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-neutral-300">
                      {{ t('serviceWorker.confirm.text') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-neutral-600 text-base font-medium text-white hover:bg-neutral-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-500 sm:col-start-2 sm:text-sm"
                  @click="updateServiceWorker()"
                >
                  {{ t('serviceWorker.confirm.confirm') }}
                </button>
                <button
                  ref="cancelButtonRef"
                  type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-neutral-400 shadow-sm px-4 py-2 bg-neutral-900 text-base font-medium text-neutral-400 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  @click="needRefresh = false"
                >
                  {{ t('serviceWorker.confirm.cancel') }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </DialogBase>
  </TransitionRoot>
</template>

<script lang="ts">
import {
  Dialog as DialogBase,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import CheckIcon from '@heroicons/vue/24/outline/CheckIcon';
// wtf is virtual module, TS is not seeing that...
// @ts-ignore
import {useRegisterSW} from 'virtual:pwa-register/vue';
import {
  Ref,
  defineComponent,
} from 'vue';
import {useI18n} from '@/plugins/I18n/i18n';

export default defineComponent({
  components: {
    DialogBase, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, CheckIcon,
  },
  props: {},
  setup() {
    const {t} = useI18n();
    const {
      needRefresh,
      updateServiceWorker,
    }: {
      needRefresh: Ref<boolean>,
      updateServiceWorker: () => Promise<void>,
    } = useRegisterSW({
      immediate: true,
      onRegistered: async (registration) => {
        if (registration) {
          // try to get new SW
          await registration.update();
          // try again in ten minutes
          setInterval(async () => {
            await registration.update();
          }, 1000 * 60 * 10);
        }
      },
    });

    return {
      needRefresh,
      t,
      updateServiceWorker,
    };
  },
});
</script>
