<template>
  <SecretButton @click="canShowVersion = !canShowVersion" />
  <div
    v-if="canShowVersion"
    class="z-10 fixed p-2 text-sm text-red-600 bottom-0 left-0"
  >
    {{ appConfig.version }}
  </div>
  <router-view />
  <NotificationWrapper class="z-30" />
  <ServiceWorkerWatch />
</template>


<script setup lang="ts">

import SecretButton from '@/modules/Core/components/SecretButton.vue';
import ServiceWorkerWatch from '@/modules/Core/components/ServiceWorkerWatch.vue';
import NotificationWrapper from '@/modules/Notification/components/NotificationWrapper.vue';

const appConfig = computed(() => APP_CONFIG);
const canShowVersion = ref(false);

const isLoading = ref(false);
const isLoadingText = ref();
provide('isLoading', isLoading);
provide('isLoadingText', isLoadingText);
</script>
