import {
  flow,
  map,
  omitBy,
} from 'lodash-es';
import isAbsoluteUrl from '@/helpers/isAbsoluteUrl';

export interface ImageProxyOptions {
  width?: number,
  height?: number,
  format?: string,
}

// check if url is absolute. If so return it.
const getUrl = (baseUrl: string, possibleRelativeUrl: string): string =>
  isAbsoluteUrl(possibleRelativeUrl) ? possibleRelativeUrl : baseUrl + possibleRelativeUrl;

export const imageProxy = (baseUrl: string, relativeUrl: string, options: ImageProxyOptions) => {
  const urlParameters = flow([
    (parameters) => omitBy(parameters, (parameter) => !parameter),
    (filteredParameters) => map(filteredParameters, (parameter, key) => [
      key,
      parameter,
    ].join('=')),
    (joinedParameters) => joinedParameters.join('&'),
  ])({
    url: getUrl(baseUrl, relativeUrl),
    width: options?.width,
    height: options?.height,
    format: options?.format,
  });

  return `http://df988106s337z.cloudfront.net/production/?${urlParameters}`;
};

export default {
  install: (app, {baseUrl}) => {
    app.config.globalProperties.$imageProxy = (relativeUrl: string, options: ImageProxyOptions) =>
      imageProxy(baseUrl, relativeUrl, options);
  },
};
