import {App} from 'vue';
import {
  Router,
  createRouter,
  createWebHistory,
} from 'vue-router';
import {ServiceAlreadyExistsError} from '@/moduleSystem/errors/ServiceAlreadyExistsError';
import {
  addService,
  getService,
} from '@/moduleSystem/inject';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = import('@/moduleSystem').ModuleSystem & {
  app: App<any>,
}

export type RegisterRouterContext = {
  router: Router,
}

export const serviceIdentifier = 'router';

export async function registerRouter(ctx: CoreRequiredContext) {
  const router = createRouter({
    history: createWebHistory(),
    routes: [...ctx.getRegisteredRoutes()],
  });

  try {
    addService<Router>(router, serviceIdentifier, {
      shared: false,
    });
  } catch (e) {
    if (!(e instanceof ServiceAlreadyExistsError)) {
      throw e;
    }
  }

  ctx.app.use(router);
  await router.push(location.pathname);
  await router.isReady();

  return {
    router,
  };
}

export const useRouter = () => getService<Router>(serviceIdentifier);
