<template>
  <button
    class="fixed bottom-0 left-0 w-10 h-10 cursor-default z-50 outline-none"
    :class="{
      'left-0': position === 'left',
      'right-0': position === 'right',
    }"
    @click="click"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
} from 'vue';

type Position = 'left' | 'right';

export default defineComponent({
  name: 'SecretButton',
  props: {
    neededClicksInMs: {
      type: Number,
      default: 2000,
    },
    neededNumberOfClicks: {
      type: Number,
      default: 5,
    },
    position: {
      type: String as PropType<Position>,
      default: 'left',
    },
  },
  emits: ['click'],
  setup(props, context) {
    let numberOfClicks = 0;
    let timeout;
    const click = () => {
      if (numberOfClicks === 0) {
        setTimeout(() => {
          numberOfClicks = 0;
        }, props.neededClicksInMs);
      } else if (numberOfClicks >= props.neededNumberOfClicks) {
        context.emit('click');
        clearTimeout(timeout);
        numberOfClicks = 0;
      }
      numberOfClicks++;
    };

    return {
      click,
    };
  },
});
</script>
