import {action} from '@designeo/vue-helpers';
import {
  Ref,
  ref,
  toRaw,
} from 'vue';
import {createUseService} from '@/moduleSystem/inject';

export enum NotificationTypeEnum {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface NotificationInterface {
  notificationType: NotificationTypeEnum,
  title: string,
  description?: string,
  timeout?: number, // in ms
}

export class NotificationManager {
  notifications: Ref<NotificationInterface[]>;

  constructor() {
    this.notifications = ref([]);
  }

  notify = action((notification: NotificationInterface) => {
    this.notifications.value.push(notification);
    setTimeout(() => this.removeNotification(notification), notification.timeout ?? 4000);
  });

  removeNotification = action((notification: NotificationInterface) => {
    this.notifications.value = this.notifications.value.filter((_notification) => {
      return toRaw(_notification) !== toRaw(notification);
    });
  });
}

export const useNotificationManger = createUseService(NotificationManager, 'NotificationManager');
