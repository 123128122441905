import {RegisterRoutesOptions} from '@/moduleSystem';

export enum ROUTES__SHARER {
  INDEX = 'routes__sharer__index',
  MEDIA = 'routes__sharer__media',
  MESSAGE = 'routes__sharer__message',
  PREVIEW = 'routes__sharer__preview',
}

export default [
  {
    path: '/:projectCode/:guid',
    component: () => import('./pages/GiftCardSharePage.vue'),
    children: [
      {
        name: ROUTES__SHARER.INDEX,
        path: '',
      },
      {
        name: ROUTES__SHARER.MEDIA,
        path: 'media',
        component: () => import('./components/steps/StepMedia.vue'),
      },
      {
        name: ROUTES__SHARER.MESSAGE,
        path: 'message',
        component: () => import('./components/steps/StepMessage.vue'),
      },
      {
        name: ROUTES__SHARER.PREVIEW,
        path: 'preview',
        component: () => import('./components/steps/StepPreview.vue'),
      },
    ],
  },
] as RegisterRoutesOptions;
