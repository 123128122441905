import {isNil} from 'lodash-es';
// import httpContext from 'express-http-context';
import {ConfigAlreadyExistsError} from '@/moduleSystem/errors/ConfigAlreadyExistsError';
import {NoSuchServiceError} from '@/moduleSystem/errors/NoSuchServiceError';
import {ServiceAlreadyExistsError} from '@/moduleSystem/errors/ServiceAlreadyExistsError';

interface Storage {
  configs: Map<any, any>,
  instances: Map<any, any>,
}

/**
 *  For SSR purposes mainly.
 *  Application has shared states in SSR mode. To distinguish client states, state must be saved separately.
 *  That's why WeakMap is used here. When client makes request, that same request object is used here as key.
 *  When request is processed, reference is invalidated, data cleaned by garbage collector and key in WeakMap
 *  is removed by it's own internal implementation.
 */
const requestsWeakMap = new WeakMap<any, Storage>();

/**
 * Shared storage is shared by all clients. Can be used for public (not personalized) purposes.
 */
export const sharedStorage: Storage = {
  configs: new Map(),
  instances: new Map(),
};

const defaultRequestId = {};

export function getRequestStorage() {
  /**
   * RequestId serves only as a reference for identification of storage.
   * This is specially useful for server side rendering where request context
   * must have it's own storage to prevent unwanted storage data sharing between clients.
   *
   * SSR
   *  - run SSR with option "runInNewContext"
   *  - set new instanceKey on global context with each request
   */
  // const instanceKey = isSsr() ? httpContext.get('request') : defaultRequestId;
  const instanceKey = defaultRequestId;
  if (!requestsWeakMap.has(instanceKey)) {
    requestsWeakMap.set(instanceKey, {
      configs: new Map(),
      instances: new Map(),
    });
  }

  const requestStorage = requestsWeakMap.get(instanceKey);

  if (!requestStorage) {
    throw new Error('No request storage.');
  }

  return requestStorage;
}

export type InstanceOfClass<T> = (T extends { new(...args: any[]): infer X } ? X : never)

export interface ServiceOptions {
  shared: boolean,
}

/**
   * Inject service into component
   */
export function createUseService<T extends { new(...args: any[]): InstanceOfClass<T> }>(
  ServiceClass: T,
  serviceIdentifier: string,
  options: ServiceOptions = {
    shared: false,
  },
) {
  return (): InstanceOfClass<T> => {
    const isShared = options?.shared ?? true;
    let instances: Storage['instances'];
    let configs: Storage['configs'];

    if (isShared) {
      instances = sharedStorage.instances;
      configs = sharedStorage.configs;
    } else {
      const requestStorage = getRequestStorage();
      instances = requestStorage.instances;
      configs = requestStorage.configs;
    }

    let service = instances.get(serviceIdentifier);

    if (service) {
      return service;
    }

    const config = configs.get(serviceIdentifier);

    service = <InstanceOfClass<T>> new ServiceClass(...(config ?? []));
    instances.set(serviceIdentifier, service);

    return service;
  };
}

/**
   * Provide service configuration from your root component to rest of the application
   * @param serviceIdentifier
   * @param options
   */
export function createConfigureService<T extends { new(...args: any[]) }>(
  serviceIdentifier: string,
  options: ServiceOptions = {
    shared: false,
  },
) {
  return (config: ConstructorParameters<T>) => {
    const isShared = options?.shared ?? true;
    let configs: Storage['configs'];

    if (isShared) {
      configs = sharedStorage.configs;
    } else {
      const requestStorage = getRequestStorage();
      configs = requestStorage.configs;
    }

    if (configs.has(serviceIdentifier)) {
      return;
      throw new ConfigAlreadyExistsError(serviceIdentifier, isShared);
    }

    configs.set(serviceIdentifier, config);
  };
}

/**
   * Add service to requestStorage
   *
   * @param serviceIdentifier
   * @param service
   * @param options
   */
export function addService<T>(
  service: T,
  serviceIdentifier: string,
  options: ServiceOptions = {
    shared: false,
  },
): T {
  const isShared = options?.shared ?? true;
  let instances: Storage['instances'];

  if (isShared) {
    instances = sharedStorage.instances;
  } else {
    const requestStorage = getRequestStorage();
    instances = requestStorage.instances;
  }

  if (instances.has(serviceIdentifier)) {
    throw new ServiceAlreadyExistsError(serviceIdentifier, isShared);
  }

  instances.set(serviceIdentifier, service);

  return service;
}

/**
   * Get service from requestStorage
   */
export function getService<T>(
  serviceIdentifier: string,
  options: ServiceOptions = {
    shared: false,
  },
): T {
  const isShared = options?.shared ?? true;
  let instances: Storage['instances'];

  if (isShared) {
    instances = sharedStorage.instances;
  } else {
    const requestStorage = getRequestStorage();
    instances = requestStorage.instances;
  }

  const service = instances.get(serviceIdentifier);
  if (isNil(service)) {
    throw new NoSuchServiceError(serviceIdentifier, isShared);
  }

  return service;
}

/**
 * @deprecated Use createUseService instead
 */
export const createUseStore = createUseService;

/**
 * @deprecated Use createConfigureService instead.
 */
export const createConfigureStore = createConfigureService;
