import {action} from '@designeo/vue-helpers';
import {merge} from 'lodash-es';
import {
  ProjectConfiguration,
  apiLoadConfiguration,
} from '@/model/action/apiLoadConfiguration';
import {
  createUseService,
  createConfigureService,
} from '@/moduleSystem/inject';

export const identifier = 'ConfigurationService';

type AvailableLanguages = 'en_US' | 'fr_FR';

export type TranslatedProperty<T> = Partial<{
  [key in AvailableLanguages]: T
}>

export interface Configuration {
  general: {
    availableLanguages: {
      [key in AvailableLanguages]: boolean
    },
    defaultLanguage: AvailableLanguages,
    maxSizeInMegaBytes: number,
    maxChars: number,
  },
  style: {
    logo: string,
    colors: {
      colorPrimary: string,
      colorSecondary: string,
      colorBackground: string,
      colorText: string,
    },
    isDarkMode: boolean,
  },
  translations: TranslatedProperty<{
    copyright: string,
    title: string,
    stepper: {
      first: string,
      second: string,
      third: string,
    },
    steps: {
      media: {
        instructions: {
          button: string,
          text: string,
          constraint: string,
        },
        buttonContinue: string,
      },
      message: {
        placeholder: string,
        buttonBack: string,
        buttonContinue: string,
      },
      preview: {
        buttonBack: string,
        buttonContinue: string,
      },
    },
    errorTitle: string,
    errors: {
      fileTypeNotSupported: string,
      fileIsTooBig: string,
      textIsTooLong: string,
      unknown: string,
    },
  }>,
}

const DEFAULT_CONFIGURATION: Configuration = {
  general: {
    availableLanguages: {
      en_US: true,
      fr_FR: true,
    },
    defaultLanguage: 'en_US',
    maxSizeInMegaBytes: 20,
    maxChars: 1500,
  },
  translations: {
    en_US: {
      title: 'Share your gift card',
      stepper: {
        first: 'Media',
        second: 'Message',
        third: 'Preview',
      },
      copyright: '© Bryanthings 2024',
      steps: {
        media: {
          buttonContinue: 'Continue',
          instructions: {
            button: 'Record or upload',
            text: 'or drag & drop your gift card message',
            constraint: 'Audio, video or image. Max size 20MB',
          },
        },
        message: {
          buttonBack: 'Back',
          buttonContinue: 'Continue',
          placeholder: 'Write your gift message',
        },
        preview: {
          buttonBack: 'Back',
          buttonContinue: 'Lock and submit',
        },
      },
      errorTitle: 'Error',
      errors: {
        fileIsTooBig: 'File is too big.',
        fileTypeNotSupported: 'File type is not supported.',
        textIsTooLong: 'Text is too long',
        unknown: 'Internal error. Please try again later.',
      },
    },
  },
  style: {
    colors: {
      colorPrimary: '#4338ca',
      colorSecondary: '#6366f1',
      colorBackground: '#ffffff',
      colorText: '#000000',
    },
    isDarkMode: false,
    logo: '/logo.jpg',
  },
};

export default class ConfigurationService {
  projectConfiguration: ProjectConfiguration<Configuration>;

  constructor(projectConfiguration: ProjectConfiguration<Configuration>) {
    this.projectConfiguration = readonly(reactive({
      ...projectConfiguration,
      configuration: merge(DEFAULT_CONFIGURATION, projectConfiguration.configuration),
    }));
    this.init();
  }

  private init = action(() => {
    const documentStyle = document.documentElement.style;
    const colors = this.projectConfiguration.configuration.style.colors;
    documentStyle.setProperty('--color-primary', colors.colorPrimary);
    documentStyle.setProperty('--color-secondary', colors.colorSecondary);
    documentStyle.setProperty('--color-background', colors.colorBackground);
    documentStyle.setProperty('--color-text', colors.colorText);

    if (this.projectConfiguration.configuration.style.isDarkMode) {
      documentStyle.setProperty('--color-sharer-gray-100', 'var(--color-gray-900)');
      documentStyle.setProperty('--color-sharer-gray-200', 'var(--color-gray-800)');
      documentStyle.setProperty('--color-sharer-gray-300', 'var(--color-gray-700)');
      documentStyle.setProperty('--color-sharer-gray-400', 'var(--color-gray-600)');
      documentStyle.setProperty('--color-sharer-gray-500', 'var(--color-gray-500)');
      documentStyle.setProperty('--color-sharer-gray-600', 'var(--color-gray-400)');
      documentStyle.setProperty('--color-sharer-gray-700', 'var(--color-gray-300)');
      documentStyle.setProperty('--color-sharer-gray-800', 'var(--color-gray-200)');
      documentStyle.setProperty('--color-sharer-gray-900', 'var(--color-gray-100)');
    }
  });

  static fetchConfiguration = action(async (shortCode: string) => {
    return (await apiLoadConfiguration({
      params: {
        shortCode,
      },
    }))! as ProjectConfiguration<Configuration>;
  });
}

export const useConfigurationService = createUseService(ConfigurationService, identifier);
export const configureConfigurationService = createConfigureService(identifier);
