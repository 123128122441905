import {
  flow,
  map,
  mapKeys,
  mapValues,
  merge,
} from 'lodash-es';
import {App} from 'vue';
import {
  VueI18n,
  ComposerOptions,
  I18n,
  createI18n,
} from 'vue-i18n';
import {useConfigurationService} from '@/modules/Backoffice/services/ConfigurationService';
import {ServiceAlreadyExistsError} from '@/moduleSystem/errors/ServiceAlreadyExistsError';
import {
  addService,
  getService,
} from '@/moduleSystem/inject';

export const FALLBACK_LANGUAGE = 'en';
export const DEFAULT_LANGUAGE = navigator.language.slice(0, 2);

/**
 * Defines what this module needs
 */
type CoreRequiredContext = import('../moduleSystem').ModuleSystem & {
  app: App<any>,
}

export type RegisterI18NContext = {
  i18n: I18n,
}

export const serviceIdentifier = 'i18n';

const czechLanguagePluralRule = (choice, choicesLength, orgRule) => {
  switch (choice) {
    case 0:
      return 0;
    case 1:
      return 1; // „den"
    case 2:
    case 3:
    case 4:
      return 2; // „dny"
    default:
      return 3; // „dnů"
  }
};

export function registerVueI18n(ctx: CoreRequiredContext) {
  const configuration = useConfigurationService().projectConfiguration.configuration;
  const i18n = createI18n({
    locale: configuration.general.defaultLanguage.slice(0, 2),
    availableLocales: map(configuration.general.availableLanguages, (value, key) => {
      if (value) {
        return key.slice(0, 2);
      }
      return false;
    }),
    fallbackLocale: FALLBACK_LANGUAGE,
    messages: merge(ctx.getTranslations(), flow([
      (translations) => mapKeys(translations, (value, key) => key.slice(0, 2)),
      (translations) => mapValues(translations, (value) => ({
        sharer: value,
      })),
    ])(configuration.translations)),
    legacy: false,
    pluralRules: {
      cs: czechLanguagePluralRule,
      sk: czechLanguagePluralRule,
    },
  } as ComposerOptions);

  try {
    addService(i18n.global, serviceIdentifier, {
      shared: false,
    });
  } catch (e) {
    if (!(e instanceof ServiceAlreadyExistsError)) {
      throw e;
    }
  }

  ctx.app.use(i18n);

  return {
    i18n,
  };
}

export const useI18n = () => getService<VueI18n>(serviceIdentifier);

