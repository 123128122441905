<template>
  <TransitionGroup
    name="list"
    tag="ul"
    class="notification-wrapper transition"
    enterActiveClass="notification-wrapper--enter-active-class"
    enterFromClass="notification-wrapper--enter-from-class"
    enterToClass="notification-wrapper--enter-to-class"
    leaveActiveClass="notification-wrapper--leave-active-class"
    leaveFromClass="notification-wrapper--leave-from-class"
    leaveToClass="notification-wrapper--leave-to-class"
  >
    <li
      v-for="notification in notifications"
      :key="notification"
    >
      <NotificationItem
        :title="notification.title"
        :description="notification.description"
        :notificationType="notification.notificationType"
        @close="removeNotification(notification)"
      />
    </li>
  </TransitionGroup>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import NotificationItem from '@/modules/Notification/components/NotificationItem.vue';
import {useNotificationManger} from '@/modules/Notification/services/NotificationManager';

export default defineComponent({
  components: {
    NotificationItem,
  },
  props: {},
  setup() {
    const {notifications, removeNotification} = useNotificationManger();

    return {
      notifications,
      removeNotification,
    };
  },
});
</script>

<style>
  .notification-wrapper {
    @apply fixed top-4 right-0 flex flex-col gap-y-3 transition w-80 max-w-full;
  }

  .notification-wrapper--enter-active-class {
    @apply transform ease-out duration-300 transition;
  }

  .notification-wrapper--enter-from-class {
    @apply translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2
  }

  .notification-wrapper--enter-to-class {
    @apply translate-y-0 opacity-100 sm:translate-x-0
  }

  .notification-wrapper--leave-active-class {
    @apply transition ease-in duration-100
  }

  .notification-wrapper--leave-from-class {
    @apply opacity-100
  }

  .notification-wrapper--leave-to-class {
    @apply opacity-0 scale-0;
  }
</style>
